<template>
  <div class="tableList">
    <div class="table">
      <el-table :data="tableData" border stripe style="width: 100%">
        <el-table-column
          prop="bidCode"
          label="招标编号"
          min-width="200"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="itemName"
          label="项目名称"
           min-width="200"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="companyName"
          label="公司名称"
           min-width="280"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="createDate"
          label="发布日期"
           min-width="200"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="createUserName"
          label="发布人"
           min-width="200"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          label="状态"
           min-width="200"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            {{
              scope.row.status == "0"
                ? "草稿"
                : scope.row.status == "1"
                ? "待评标"
                : scope.row.status == "2"
                ? "待报价"
                : scope.row.status == "3"
                ? "已结束"
                : scope.row.status == "4"
                ? "废标"
                : scope.row.status == "5"
                ? "截止应标"
                : scope.row.status == "6"
                ? "待处理"
                : scope.row.status == "7"
                ? "审批中"
                : scope.row.status == "8"
                ? "复标待报价"
                : scope.row.status
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right"   min-width="150">
          <template #default="scope">
            <el-button
              v-if="getFbShow(scope)"
              size="mini"
              type="text"
              @click="reject(scope.$index, scope.row)"
              >废标</el-button
            >
            <el-button
              v-if="scope.row.status == 0"
              size="mini"
              type="text"
              @click="edit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button v-if="false" size="mini" type="text">复制</el-button>
            <el-button
              size="mini"
              type="text"
              @click="handleShow(scope.$index, scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="tablepage" v-if="tableData && tableData.length > 0">
        <el-pagination
          @current-change="currentChange"
          :page-size="paginationData.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="paginationData.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '@/assets/js/http.js'
import { FlowCtlData } from '@/assets/js/FlowManage.js'
export default {
  name: 'InvitationTable',
  props: ['tableData', 'paginationData'],
  data () {
    FlowCtlData.docid = ''
    FlowCtlData.flowid = 'bid'
    FlowCtlData.initialize()
    return {}
  },
  methods: {
    getFbShow (scope) {
      return (
        scope.row.status !== 4 &&
        scope.row.status !== 3 &&
        scope.row.createUserName === localStorage.getItem('userName')
      )
    },
    currentChange: function (data) {
      this.$emit('pageChange', data)
    },
    reject: function (index, rowData) {
      FlowCtlData.flowid = 'bid'
      FlowCtlData.docid = rowData.bidId
      FlowCtlData.initialize().then(() => {
        rowData.bhct = 's'
        rowData.id = rowData.bidId
        FlowCtlData.loginID = 'bidAdmin'
        FlowCtlData.getNodeAction('TJ', rowData)
          .then((val) => {
            if (val) {
              FlowCtlData.alertDialog(this)
                .then(() => {
                  FlowCtlData.setFormData(FlowCtlData, rowData)
                  rowData.approvalComplete =
                    rowData.approvalComplete +
                    localStorage.getItem('userName') +
                    '(执行废标操作),'
                  request('/api/bid/approvalSave', 'post', rowData).then(
                    (response) => {
                      if (response.code === '200') {
                        request('/api/bid/updateByBidId', 'post', {
                          id: rowData.bidId,
                          status: 4
                        }).then((response) => {
                          if (response.code === '200') {
                            FlowCtlData.setNodeAction().then((val) => {
                              if (val) {
                                FlowCtlData.formAlert(this, 'success')
                                rowData.status = 4
                              }
                            })
                          }
                        })
                      }
                    }
                  )
                })
                .catch(() => {
                  FlowCtlData.formAlert(this, 'info')
                })
            }
          })
          .catch(() => {
            FlowCtlData.formAlert(this, 'error')
          })
      })
    },
    edit: function (index, data) {
      this.$emit('buttonClick', {
        alias: 'BidForm',
        openView: 'openView',
        query: { id: data.bidId }
      })
    },
    handleShow: function (index, data) {
      this.$emit('buttonClick', {
        alias: 'BidFormAll',
        openView: 'openView',
        query: { id: data.bidId }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/tableList.scss";
</style>
