<template>
    <div>
      <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
      <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
      <invitation-table @buttonClick="buttonClick" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"></invitation-table>
    </div>
</template>

<script>
import ListSearch from '@/components/actionList/ListSearch.vue'
import InvitationTable from './InvitationTable.vue'
import { SearchList, SearchData } from './js/invitationConfig.js'
import ListMenu from '@/components/actionList/ListMenu.vue'
import { request } from '@/assets/js/http.js'
import dayjs from 'dayjs'
export default {
  components: { ListSearch, InvitationTable, ListMenu },
  name: 'Invitation',
  data: function () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      childData: { searchList: SearchList, searchData: SearchData },
      menudata: this.$store.state.ViewMenuData.menuData,
      paginationData: {},
      pageSize: pageSize,
      tableData: [],
      searchdata: {}
    }
  },
  created: function () {
    this.$nextTick(function () {
      request('/api/bid/list?pageNum=' + 1 + '&' + 'pageSize=' + this.pageSize, 'get', this.searchdata).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.paginationData = { pageSize: this.pageSize, total: res.data.total }
        }
      })
    })
  },
  methods: {
    buttonClick: function (data) {
      if (data.openView === 'openView') {
        this.$router.push({ params: { viewName: data.alias }, query: data.query })
        this.$emit('OpenView', { openView: data.alias })
      }
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD HH:mm:ss')
    },
    pageChange: function (data) {
      request('/api/bid/list?pageNum=' + data + '&' + 'pageSize=' + this.pageSize, 'get', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
        }
      })
    },
    search: function (data) {
      this.searchdata = { ...data }
      delete this.searchdata.tempDate
      if (data.tempDate) {
        this.searchdata.endTime = this.dateFormate(data.tempDate[1])
        this.searchdata.startTime = this.dateFormate(data.tempDate[0])
      }
      this.$nextTick(function () {
        request('/api/bid/list?pageNum=' + 1 + '&' + 'pageSize=' + this.pageSize, 'get', this.searchdata).then((res) => {
          if (res.code === '200') {
            this.tableData = res.data.records
            this.paginationData = { pageSize: this.pageSize, total: res.data.total }
          }
        })
      })
    }
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    }
  }
}
</script>

<style scoped lang="scss">
</style>
