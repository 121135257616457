export var SearchList = [
  { labe: '招标编号', code: 'code', type: 'input' },
  { labe: '项目名称', code: 'itemName', type: 'input' },
  { labe: '公司名称', code: 'company', type: 'input', placeholder: '编码/名称' },
  {
    labe: '状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '草稿', value: '0' },
      { label: '待评标', value: '1' },
      { label: '待报价', value: '2' },
      { label: '已结束', value: '3' },
      { label: '废标', value: '4' }
    ]
  },
  {
    labe: '发布日期',
    code: 'tempDate',
    type: 'daterange'
  }
]
export var SearchData = {
  code: '',
  itemName: '',
  company: '',
  status: '',
  endTime: '',
  startTime: ''
}
